import React, { useCallback, useState } from "react";
import { ConfirmDialog } from "components/common/confirm/ConfirmDialog";
import { DialogProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ButtonProps } from "@mui/material/Button/Button";

export interface ConfirmOptions<T = void> {
    title?: string;
    message?: string;
    hideCancelButton?: boolean;
    content?: React.ReactNode;
    size?: DialogProps["maxWidth"];
    confirmButtonText?: string;
    extraActions?: Array<{
        type: T;
        isConfirmed: boolean;
        buttonProperties: ButtonProps;
    }>;
}

export type ConfirmResult<T> = {
    isConfirmed: boolean;
    type?: "confirm" | T;
};
export type ConfirmCallback = <T>(
    options?: ConfirmOptions<T>,
) => Promise<ConfirmResult<T>>;
type ResolveFunction<T> = (
    result: ConfirmResult<T> | PromiseLike<ConfirmResult<T>>,
) => void;

export const ConfirmContext = React.createContext<ConfirmCallback | undefined>(
    undefined,
);

export interface ConfirmProviderProps extends React.PropsWithChildren {}

export const ConfirmProvider = ({ children }: ConfirmProviderProps) => {
    const [options, setOptions] = useState<ConfirmOptions<any>>({});
    const [resolveFuncList, setResolveFuncList] = useState<
        [ResolveFunction<any>] | []
    >([]);
    const resolveFunc = resolveFuncList[0];
    const { t } = useTranslation();

    const confirm = <T,>(o: ConfirmOptions<T> = {}) => {
        return new Promise<ConfirmResult<T>>((resolve) => {
            setOptions({
                title: o.title,
                message: o.message,
                hideCancelButton: o.hideCancelButton,
                content: o.content,
                confirmButtonText: o.confirmButtonText,
                extraActions: o.extraActions,
                size: o.size,
            });
            setResolveFuncList([resolve]);
        });
    };

    const closeDialog = useCallback(() => {
        setResolveFuncList([]);
    }, [setResolveFuncList]);

    const onCancel = useCallback(() => {
        resolveFunc?.({ isConfirmed: false });
        closeDialog();
    }, [resolveFunc, closeDialog]);

    const onConfirm = useCallback(
        <T,>(result: ConfirmResult<T>) => {
            resolveFunc?.(result);
            closeDialog();
        },
        [resolveFunc, closeDialog],
    );

    return (
        <>
            <ConfirmContext.Provider value={confirm}>
                {children}
            </ConfirmContext.Provider>
            <ConfirmDialog
                onConfirm={onConfirm}
                open={resolveFunc !== undefined}
                title={options.title ?? t("are_you_sure")}
                onClose={onCancel}
                hideCancelButton={options.hideCancelButton ?? false}
                confirmButtonText={options.confirmButtonText ?? t("continue")}
                extraActions={options.extraActions}
                maxWidth={options.size ?? "xs"}
            >
                <Typography>{options.message ?? ""}</Typography>
                {options.content}
            </ConfirmDialog>
        </>
    );
};
