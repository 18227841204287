import { Functionality, MeType } from "types/graphql";
import {
    getActiveOrgCompany,
    getActiveOrgCustomer,
} from "Entity/ActiveOrgHandler";
import { ID_EMPTY } from "Utils/constants";
import React from "react";
import { MeEmpty } from "Utils/empties";

export {
    ActiveNorreUserContext,
    deleteActiveNorreUserStorage,
} from "Entity/ActiveUser/NorreUser";

export const ACTIVE_AUTH_USER_STORAGE_KEY = "__active_auth_user";

export const deleteActiveUserStorage = () => {
    localStorage.removeItem(ACTIVE_AUTH_USER_STORAGE_KEY);
};

export const getActiveOrganizationName = (
    functionality: Functionality,
): string => {
    const customerId = getActiveOrgCustomer();
    const companyId = getActiveOrgCompany();

    if (companyId !== ID_EMPTY) {
        const company = functionality.companies.find((c) => c.id === companyId);
        return company ? company.name : "";
    } else if (customerId !== ID_EMPTY) {
        const customer = functionality.customers.find(
            (c) => c.id === customerId,
        );
        return customer ? customer.name : "";
    } else {
        return "";
    }
};

export interface SetActiveAuthUserProp {
    setActiveAuthUser: (value: MeType | null) => void;
}

export interface ActiveAuthUserProp {
    activeAuthUser: MeType | null;
}

export const ActiveAuthUserContext = React.createContext({
    activeAuthUser: MeEmpty,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setActiveAuthUser: (value: MeType | null) => {},
});
