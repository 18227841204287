import React, { useCallback } from "react";
import { useStoredState } from "Utils/storedState";
import { Functionality, Scalars } from "types/graphql";
import {
    ACTIVE_COMPANY_STORAGE_KEY,
    ACTIVE_CUSTOMER_STORAGE_KEY,
} from "Entity/ActiveOrgHandler";
import { NorreFunctionalityContext } from "Entity/Functionality";
import { FunctionalityEmpty } from "Utils/empties";
import { NORRE_FUNCTIONALITY_STORAGE_KEY } from "Entity/Functionality/BackendFunctionality";

export interface AppContextWrapperProps extends React.PropsWithChildren {}

export const AppContextWrapper = ({ children }: AppContextWrapperProps) => {
    const [activeCompany, setActiveCompany] = useStoredState<
        Scalars["ID"]["input"] | null
    >(ACTIVE_COMPANY_STORAGE_KEY, null, "string");

    const [activeCustomer, setActiveCustomer] = useStoredState<
        Scalars["ID"]["input"] | null
    >(ACTIVE_CUSTOMER_STORAGE_KEY, null, "string");

    const [functionality, setFunctionality] =
        useStoredState<Functionality | null>(
            NORRE_FUNCTIONALITY_STORAGE_KEY,
            null,
        );

    const setActiveCustomerWithReset = useCallback(
        (
            value: Scalars["ID"]["input"] | null,
            resetCompany: boolean = true,
        ) => {
            setActiveCustomer(value);
            if (resetCompany) {
                setActiveCompany(null);
            }
        },
        [setActiveCompany, setActiveCustomer],
    );

    const setActiveCompanyWithReset = useCallback(
        (
            value: Scalars["ID"]["input"] | null,
            resetCustomer: boolean = true,
        ) => {
            setActiveCompany(value);
            if (resetCustomer) {
                setActiveCustomer(null);
            }
        },
        [setActiveCompany, setActiveCustomer],
    );

    return (
        <ActiveCompanyContext.Provider
            value={{
                activeCompany: activeCompany,
                setActiveCompany: setActiveCompanyWithReset,
            }}
        >
            <ActiveCustomerContext.Provider
                value={{
                    activeCustomer: activeCustomer,
                    setActiveCustomer: setActiveCustomerWithReset,
                }}
            >
                <NorreFunctionalityContext.Provider
                    value={{
                        functionality: functionality || FunctionalityEmpty,
                        setFunctionality: setFunctionality,
                    }}
                >
                    {children}
                </NorreFunctionalityContext.Provider>
            </ActiveCustomerContext.Provider>
        </ActiveCompanyContext.Provider>
    );
};

// TODO: Wrap login sites so that active user contexts not needed.

export const ActiveCompanyContext = React.createContext({
    activeCompany: null as Scalars["ID"]["input"] | null,
    setActiveCompany: (
        value: Scalars["ID"]["input"] | null,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        resetCustomer: boolean = true,
    ) => {},
});

export const ActiveCustomerContext = React.createContext({
    activeCustomer: null as Scalars["ID"]["input"] | null,
    setActiveCustomer: (
        value: Scalars["ID"]["input"] | null,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        resetCompany = true,
    ) => {},
});
