import React, { Suspense } from "react";
import {
    BrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    Route,
    Routes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import {
    AUTH,
    IDENTIFICATION_CALLBACK,
    IDENTIFICATION_FAILED,
    INVALID_PASSWORD_LINK,
    LOGIN,
    PASSWORD,
    REQUEST_PASSWORD_RESET,
    RESET_PASSWORD,
    SET_PASSWORD,
    STRONG_IDENTIFICATION,
} from "urls";
import { ACTIVE_AUTH_USER_STORAGE_KEY } from "Entity/ActiveUser";
import { useStoredState } from "Utils/storedState";
import { MeType } from "types/graphql";
import { PublicPageLoading } from "components/common/PageTemplates/PublicPageLoading";
import {
    ACTIVE_NORRE_USER_STORAGE_KEY,
    ActiveNorreUser,
} from "Entity/ActiveUser/NorreUser";
import * as Sentry from "@sentry/react";
import { api_base, isDevEnv, isProdEnv } from "Utils/requests";
import { componentLoader } from "Utils/componentLoader";

const LoginRequiredRoutes = React.lazy(() =>
    componentLoader(() => import("LoginRequiredRoutes")),
);

const AuthPage = React.lazy(() =>
    componentLoader(
        () => import("components/views/LoginViews/AuthPage/AuthPage"),
    ),
);

const LoginStrongIdentificationView = React.lazy(() =>
    componentLoader(
        () =>
            import(
                "components/views/LoginViews/StrongIdentification/StrongIdentificationView"
            ),
    ),
);
const LoginIdentificationFailedCallbackView = React.lazy(() =>
    componentLoader(
        () =>
            import(
                "components/views/LoginViews/StrongIdentification/IdentificationFailedCallbackView"
            ),
    ),
);
const LoginIdentificationCallbackView = React.lazy(() =>
    componentLoader(
        () =>
            import(
                "components/views/LoginViews/StrongIdentification/IdentificationCallbackView"
            ),
    ),
);
const LoginSetPasswordView = React.lazy(() =>
    componentLoader(
        () =>
            import("components/views/LoginViews/PasswordViews/SetPasswordView"),
    ),
);
const LoginRequestPasswordResetView = React.lazy(() =>
    componentLoader(
        () =>
            import(
                "components/views/LoginViews/PasswordViews/RequestPasswordResetView"
            ),
    ),
);
const LoginResetPasswordView = React.lazy(() =>
    componentLoader(
        () =>
            import(
                "components/views/LoginViews/PasswordViews/ResetPasswordView"
            ),
    ),
);
const LoginInvalidLinkView = React.lazy(() =>
    componentLoader(
        () =>
            import("components/views/LoginViews/PasswordViews/InvalidLinkView"),
    ),
);
const LoginPage = React.lazy(() =>
    componentLoader(
        () => import("components/views/LoginViews/LoginPage/LoginPage"),
    ),
);

Sentry.init({
    dsn: "https://236cd6f9e59a9cc0b9c6990e5f3aef99@o4507192475320320.ingest.de.sentry.io/4507214576287824",
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    enabled: !isDevEnv(),
    environment: isProdEnv() ? "prod" : "test",
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [api_base],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const AppRouter = () => {
    const [activeAuthUser, setActiveAuthUser] = useStoredState<MeType | null>(
        ACTIVE_AUTH_USER_STORAGE_KEY,
        null,
    );

    const [activeNorreUser, setActiveNorreUser] =
        useStoredState<ActiveNorreUser>(ACTIVE_NORRE_USER_STORAGE_KEY, null);

    return (
        <BrowserRouter>
            <SentryRoutes>
                <Route
                    path={STRONG_IDENTIFICATION}
                    element={
                        <Suspense fallback={<PublicPageLoading />}>
                            <LoginStrongIdentificationView />
                        </Suspense>
                    }
                />
                <Route
                    path={IDENTIFICATION_FAILED}
                    element={
                        <Suspense fallback={<PublicPageLoading />}>
                            <LoginIdentificationFailedCallbackView />
                        </Suspense>
                    }
                />
                <Route
                    path={IDENTIFICATION_CALLBACK}
                    element={
                        <Suspense fallback={<PublicPageLoading />}>
                            <LoginIdentificationCallbackView />
                        </Suspense>
                    }
                />
                <Route path={PASSWORD}>
                    <Route
                        path={SET_PASSWORD}
                        element={
                            <Suspense fallback={<PublicPageLoading />}>
                                <LoginSetPasswordView />
                            </Suspense>
                        }
                    />
                    <Route
                        path={REQUEST_PASSWORD_RESET}
                        element={
                            <Suspense fallback={<PublicPageLoading />}>
                                <LoginRequestPasswordResetView />
                            </Suspense>
                        }
                    />
                    <Route
                        path={RESET_PASSWORD}
                        element={
                            <Suspense fallback={<PublicPageLoading />}>
                                <LoginResetPasswordView />
                            </Suspense>
                        }
                    />
                    <Route
                        path={INVALID_PASSWORD_LINK}
                        element={
                            <Suspense fallback={<PublicPageLoading />}>
                                <LoginInvalidLinkView />
                            </Suspense>
                        }
                    />
                </Route>
                <Route
                    path={LOGIN}
                    element={
                        <Suspense fallback={<PublicPageLoading />}>
                            <LoginPage
                                setActiveAuthUser={setActiveAuthUser}
                                setActiveNorreUser={setActiveNorreUser}
                            />
                        </Suspense>
                    }
                />
                <Route
                    path={AUTH}
                    element={
                        <Suspense fallback={<PublicPageLoading />}>
                            <AuthPage />
                        </Suspense>
                    }
                />
                <Route
                    path="*"
                    element={
                        <Suspense fallback={<PublicPageLoading />}>
                            <LoginRequiredRoutes
                                activeAuthUser={activeAuthUser}
                                setActiveAuthUser={setActiveAuthUser}
                                activeNorreUser={activeNorreUser}
                                setActiveNorreUser={setActiveNorreUser}
                            />
                        </Suspense>
                    }
                />
            </SentryRoutes>
        </BrowserRouter>
    );
};
