import { alpha, createTheme, getContrastRatio } from "@mui/material/styles";
import {
    NORRE_FONT,
    NORREBACKGROUND,
    NORREDARKGRAY,
    NORREDARKHOVER,
    NORREERROR,
    NORREHEADER,
    NORRELIGHTGRAY,
    NORREPRIMARY,
    NORRERED,
    NORRESECONDARY,
    NORRETABLEHEADERSTYLE,
    NORRETASKCOMPLETED,
    NORRETASKWARNING,
    NORREWHITE,
} from "Utils/styleConstants";

declare module "@mui/material/styles" {
    interface Palette {
        alert: Palette["primary"];
        yellow: Palette["primary"];
        completed: Palette["primary"];
    }

    interface PaletteOptions {
        alert?: PaletteOptions["primary"];
        yellow?: PaletteOptions["primary"];
        completed?: PaletteOptions["primary"];
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        alert: true;
        yellow: true;
        completed: true;
    }
}

export const NORRE_GLOBAL_THEME = createTheme({
    palette: {
        primary: {
            main: NORREPRIMARY,
        },
        secondary: {
            main: NORRESECONDARY,
        },
        background: {
            default: NORREBACKGROUND,
        },
        alert: {
            main: alpha(NORREERROR, 0.3),
            light: alpha(NORREERROR, 0.15),
            dark: alpha(NORREERROR, 0.5),
            contrastText:
                getContrastRatio(alpha(NORREERROR, 0.3), NORREERROR) > 0.8
                    ? NORREERROR
                    : NORREWHITE,
        },
        yellow: {
            main: alpha(NORRETASKWARNING, 0.3),
            light: alpha(NORRETASKWARNING, 0.15),
            dark: alpha(NORRETASKWARNING, 0.5),
            contrastText:
                getContrastRatio(
                    alpha(NORRETASKWARNING, 0.3),
                    NORRETASKWARNING,
                ) > 0.8
                    ? NORRETASKWARNING
                    : NORREWHITE,
        },
        completed: {
            main: alpha(NORRETASKCOMPLETED, 0.3),
            light: alpha(NORRETASKCOMPLETED, 0.15),
            dark: alpha(NORRETASKCOMPLETED, 0.5),
            contrastText:
                getContrastRatio(
                    alpha(NORRETASKCOMPLETED, 0.3),
                    NORRETASKCOMPLETED,
                ) > 0.8
                    ? NORRETASKCOMPLETED
                    : NORREWHITE,
        },
    },
    typography: {
        fontFamily: NORRE_FONT,
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    backgroundColor: NORREWHITE,
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                "@fontFace": [NORRE_FONT],
                h1: {
                    color: NORRERED,
                    fontSize: "3rem",
                    textTransform: "uppercase",
                },
                h2: {
                    color: NORREHEADER,
                    textTransform: "uppercase",
                    fontSize: "1.5rem",
                    marginTop: "3rem",
                    marginBottom: "2rem",
                },
                h3: {
                    color: NORREHEADER,
                    fontSize: "1.5rem",
                    marginTop: "2rem",
                    marginBottom: "1rem",
                },
                h4: {
                    color: NORREHEADER,
                    fontSize: "1.3rem",
                    marginTop: "1.5rem",
                    marginBottom: "0.75rem",
                },

                th: {
                    textTransform: "uppercase",
                },

                "*::-webkit-scrollbar-track": {
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                    backgroundColor: NORRELIGHTGRAY,
                },

                "*::-webkit-scrollbar": {
                    height: "8px",
                    width: "8px",
                    backgroundColor: NORRELIGHTGRAY,
                },

                "*::-webkit-scrollbar-thumb": {
                    backgroundColor: NORREDARKGRAY,
                    border: `2px solid ${NORREWHITE}`,
                    borderRadius: "6px",
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    color: NORREPRIMARY,
                    backgroundColor: NORREWHITE,
                    borderColor: NORREPRIMARY,
                    "&$selected": {
                        backgroundColor: NORREPRIMARY,
                        color: NORREWHITE,
                        "&:hover": {
                            color: NORREPRIMARY,
                            backgroundColor: NORREDARKHOVER,
                        },
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            },
        },
        MuiList: {
            styleOverrides: {
                padding: {
                    paddingBottom: "0px",
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    "&$selected": {
                        backgroundColor: NORREWHITE,
                        "&:hover": {
                            backgroundColor: NORREWHITE,
                        },
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: NORREPRIMARY,
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: NORREPRIMARY,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    marginRight: "2vw",
                    marginLeft: "2vw",
                    "& .txtNorre, .ddlNorre": {
                        marginBottom: "0.75rem",
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: NORREHEADER,
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: "0.5rem !important",
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    "& th": NORRETABLEHEADERSTYLE,
                },
            },
        },
        MuiTableFooter: {
            styleOverrides: {
                root: {
                    "& p": {
                        marginBottom: "0",
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: NORREBACKGROUND,
                },
            },
        },
    },
});
