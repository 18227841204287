import { RetryLink } from "@apollo/client/link/retry";

export const retryLink = new RetryLink({
    delay: {
        initial: 800,
        max: Infinity,
        jitter: true,
    },
    attempts: {
        max: 5,
        retryIf: (error) => !!error,
    },
});
