import {
    Functionality,
    PermissionChoices,
    Scalars,
    ViewViewTypeChoices,
} from "types/graphql";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useContext } from "react";
import {
    CORPORATE_ADMIN_HOME,
    CUSTOMER_ADMIN_HOME,
    DOCTOR_HOME,
    REGIONAL_ADMIN_HOME,
    SUPPLIER_HOME,
} from "urls";
import i18n from "i18next";
import { FunctionalityEmpty } from "Utils/empties";
import {
    AuthPageTabsType,
    AuthPermissions,
    TabsType,
} from "Entity/Authentication/auth";
import { PageNameType, PageType } from "types/utils";
import { useTranslation } from "react-i18next";
import { useFunctionalityLazyQuery } from "Apollo/Queries/FunctionalityQueries/index.generated";
import { ApolloError } from "@apollo/client";

export const NORRE_FUNCTIONALITY_STORAGE_KEY = "__norre_functionality";

export const removeNorreFunctionalityStorage = () => {
    localStorage.removeItem(NORRE_FUNCTIONALITY_STORAGE_KEY);
};

export const getNorreFunctionalityStorage = (): Functionality => {
    const fromStorage = localStorage.getItem(NORRE_FUNCTIONALITY_STORAGE_KEY);
    return fromStorage ? JSON.parse(fromStorage) : FunctionalityEmpty;
};

export const NorreFunctionalityContext = React.createContext({
    functionality: FunctionalityEmpty,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setFunctionality: (value: Functionality | null) => {},
});

export function useNavigateToBackendUserHome() {
    const navigate = useNavigate();

    return useCallback(
        (uiOverride?: ViewViewTypeChoices) => {
            navigate(
                mapBackendViewTypeToRoute(
                    uiOverride
                        ? uiOverride
                        : getNorreFunctionalityStorage().primaryUi,
                ),
            );
        },
        [navigate],
    );
}

export const mapBackendViewTypeToRoute = (
    uiType: ViewViewTypeChoices,
): string => {
    switch (uiType) {
        case ViewViewTypeChoices.Corporateadmin:
            return CORPORATE_ADMIN_HOME;
        case ViewViewTypeChoices.Regionaladmin:
            return REGIONAL_ADMIN_HOME;
        case ViewViewTypeChoices.Customeradmin:
            return CUSTOMER_ADMIN_HOME;
        case ViewViewTypeChoices.Healthcare:
            return DOCTOR_HOME;
        case ViewViewTypeChoices.Supplier:
            return SUPPLIER_HOME;
    }
};

export const onChangeBackendFunctionality = (functionality: Functionality) => {
    const t = i18n.t;

    i18n.changeLanguage(functionality.language);

    if (
        !functionality.showCorporateAdminViews &&
        !functionality.showRegionalAdminViews &&
        !functionality.showCustomerAdminViews &&
        !functionality.showHealthcareWorkerViews &&
        !functionality.showSupplierViews
    ) {
        alert(t("error_user_has_no_permissions"));
    }
};

export function useUpdateBackendFunctionality() {
    const { setFunctionality } = useContext(NorreFunctionalityContext);

    const [queryFunctionality] = useFunctionalityLazyQuery({
        fetchPolicy: "network-only",
    });

    function updateBackendFunctionality(
        companyId: Scalars["ID"]["input"] | null,
        customerId: Scalars["ID"]["input"] | null,
        onCompleted?: (functionality: Functionality) => void,
        onError?: (err: ApolloError) => void,
    ): void {
        queryFunctionality({
            variables: {
                customerId: customerId,
                companyId: companyId,
            },
            onError: onError,
            onCompleted: (data) => {
                setFunctionality(data.functionality);
                onChangeBackendFunctionality(data.functionality);
                if (onCompleted) {
                    onCompleted(data.functionality);
                }
            },
        });
    }

    return updateBackendFunctionality;
}

export function useCheckBackendPermission() {
    const { functionality } = useContext(NorreFunctionalityContext);

    function checkBackendPermission(
        perms: PermissionChoices | PermissionChoices[],
    ): boolean {
        if (Array.isArray(perms)) {
            return perms.every((perm) => functionality.perms.includes(perm));
        } else {
            return functionality.perms.includes(perms);
        }
    }

    return checkBackendPermission;
}

export function isArrayOfPermissionChoicesArrays(
    obj: PermissionChoices[][] | PermissionChoices[],
): obj is PermissionChoices[][] {
    return Array.isArray(obj[0]);
}

const checkAuthPermissions = (
    functionality: Functionality,
    perms: AuthPermissions,
): boolean => {
    // Normalize to array of arrays
    const permsArray = isArrayOfPermissionChoicesArrays(perms)
        ? perms
        : [perms];
    return permsArray.some((permSet) =>
        permSet.every((perm) => functionality.perms.includes(perm)),
    );
};

export function useCheckPagePermissions() {
    const { functionality } = useContext(NorreFunctionalityContext);

    function checkPagePermissions(
        page: PageType,
        pageName?: PageNameType,
    ): boolean {
        const perms = extractAuthPermsFromPage(page, pageName);
        return checkAuthPermissions(functionality, perms);
    }

    return checkPagePermissions;
}

export function useCheckTabPermissions() {
    const { functionality } = useContext(NorreFunctionalityContext);
    const { t } = useTranslation();

    function checkTabPermissions(
        pageTabsOrPerms: AuthPageTabsType | AuthPermissions | undefined,
        tab: TabsType,
    ): boolean {
        if (pageTabsOrPerms === undefined) {
            console.error(t("error_check_page_perms_undefined"));
            return false;
        }

        if (Array.isArray(pageTabsOrPerms)) {
            // If we have a list of permission, it means this page shouldn't have tabs
            return false;
        }

        const tabPermissions = pageTabsOrPerms[tab];

        if (tabPermissions === undefined) {
            // If the tab doesn't exist in this page
            console.error(t("error_check_tab_perms_undefined", { tab: tab }));
            return false;
        }

        return checkAuthPermissions(functionality, tabPermissions);
    }

    return checkTabPermissions;
}

const extractAuthPermsFromPage = (
    page: PageType,
    pageName?: PageNameType,
): AuthPermissions => {
    let allPerms: AuthPermissions = [];

    // Type guard to check if it has children
    if ("children" in page && page.children) {
        allPerms = page.children.reduce((acc: AuthPermissions, child) => {
            const childPerms = extractAuthPermsFromPage(child);
            if (Array.isArray(childPerms) && Array.isArray(acc)) {
                return [...acc, ...childPerms] as AuthPermissions;
            }
            return acc;
        }, []);
    }

    let pageTabsOrPerms = page.perms;

    // If pageName is provided, filter by it
    if (
        pageName &&
        typeof pageTabsOrPerms === "object" &&
        pageTabsOrPerms !== null
    ) {
        if (pageName in (pageTabsOrPerms as Record<string, unknown>)) {
            pageTabsOrPerms = (
                pageTabsOrPerms as Record<string, AuthPermissions>
            )[pageName];
        } else {
            return allPerms;
        }
    }

    if (pageTabsOrPerms === undefined) return allPerms;

    // Handle an array of permissions or array of array of permissions
    if (Array.isArray(pageTabsOrPerms)) {
        return pageTabsOrPerms;
    }

    // Handle tabs
    return Object.values(pageTabsOrPerms).map((p) => p.flat());
};
