import { ComponentType } from "react";

export const componentLoader = <T extends ComponentType<any>>(
    load: () => Promise<{ default: T }>,
    attemptsLeft: number = 3,
): Promise<{ default: T }> => {
    return new Promise((resolve, reject) => {
        load()
            .then(resolve)
            .catch((error) => {
                // let us retry after 1500 ms
                setTimeout(() => {
                    if (attemptsLeft === 1) {
                        reject(error);
                        return;
                    }
                    componentLoader(load, attemptsLeft - 1).then(
                        resolve,
                        reject,
                    );
                }, 1500);
            });
    });
};
