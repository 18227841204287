import React from "react";
import { DialogContent, DialogProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { NorreButton } from "components/common/NorreButton";
import {
    ConfirmOptions,
    ConfirmResult,
} from "components/common/confirm/ConfirmProvider";

export interface ConfirmDialogProps<T>
    extends DialogProps,
        Pick<ConfirmOptions<T>, "extraActions" | "hideCancelButton"> {
    title: string;
    onConfirm: (result: ConfirmResult<T>) => void;
    onCancel?: () => void;
    confirmButtonText: string;
}

export const ConfirmDialog = <T,>({
    title,
    onConfirm,
    onCancel,
    onClose,
    children,
    hideCancelButton = false,
    confirmButtonText,
    extraActions = [],
    ...other
}: Omit<ConfirmDialogProps<T>, "fullWidth" | "fullScreen">) => {
    const { t } = useTranslation();
    return (
        <Dialog fullWidth={true} onClose={onClose} {...other}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <NorreButton
                    onClick={(e) => {
                        e.preventDefault();
                        onConfirm({
                            isConfirmed: true,
                            type: "confirm",
                        });
                    }}
                >
                    {confirmButtonText}
                </NorreButton>
                {extraActions.map(
                    ({ type, isConfirmed, buttonProperties }, index) => {
                        return (
                            <NorreButton
                                key={index}
                                {...buttonProperties}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onConfirm({
                                        isConfirmed: isConfirmed,
                                        type: type,
                                    });
                                }}
                            >
                                {buttonProperties.children}
                            </NorreButton>
                        );
                    },
                )}
                {hideCancelButton ? (
                    <></>
                ) : (
                    <NorreButton
                        color={"secondary"}
                        onClick={(e) => {
                            e.preventDefault();
                            if (onCancel) {
                                onCancel();
                            } else if (onClose) {
                                onClose(e, "escapeKeyDown");
                            }
                        }}
                    >
                        {t("cancel")}
                    </NorreButton>
                )}
            </DialogActions>
        </Dialog>
    );
};
