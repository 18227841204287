import React from "react";
import { ApolloProvider } from "@apollo/client";
import client from "Apollo/config";
import { I18nextProvider } from "react-i18next";
import i18n from "localization/i18n";
import { AppRouter } from "AppRouter";
import { ConfirmProvider } from "components/common/confirm/ConfirmProvider";
import { AppContextWrapper } from "AppContextWrapper";
import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";

const SENTRY_URL =
    "https://o4507192475320320.ingest.de.sentry.io/api/4507214576287824/";

function App() {
    const cspDirectives: string =
        process.env.NODE_ENV === "development"
            ? `
              default-src 'self' data: https://storage.googleapis.com;
              font-src 'self' fonts.gstatic.com;
              img-src 'self' data: https://storage.googleapis.com https://flagcdn.com;
              script-src 'self';
              style-src 'self' 'unsafe-inline' fonts.googleapis.com;
              connect-src 'self' localhost:8000 https://localhost:8000 https://localhost:8020 ${SENTRY_URL};
              worker-src 'self' blob:;
            `
            : `
              default-src 'self' data: https://storage.googleapis.com;
              font-src 'self' fonts.gstatic.com;
              img-src 'self' data: https://storage.googleapis.com https://flagcdn.com;
              script-src 'self';
              style-src 'self' 'unsafe-inline' fonts.googleapis.com;
              connect-src 'self' api.norre.fi api-test.norre.fi vpn-service.norre.fi ${SENTRY_URL};
              worker-src 'self' blob:;
            `;

    return (
        <I18nextProvider i18n={i18n}>
            <Helmet>
                <meta
                    httpEquiv="Content-Security-Policy"
                    content={cspDirectives}
                />
            </Helmet>
            <Box
                sx={{
                    height: "100%",
                    display: "flex",
                }}
            >
                <ApolloProvider client={client}>
                    <ConfirmProvider>
                        <AppContextWrapper>
                            <AppRouter />
                        </AppContextWrapper>
                    </ConfirmProvider>
                </ApolloProvider>
            </Box>
        </I18nextProvider>
    );
}

export default App;
