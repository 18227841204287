import React from "react";
import { NorreBackendUserQuery } from "Apollo/Queries/UserQueries/index.generated";

export type ActiveNorreUser = NorreBackendUserQuery["norreBackendUser"] | null;

export interface SetActiveNorreUserProp {
    setActiveNorreUser: (value: ActiveNorreUser) => void;
}

export interface ActiveNorreUserProp {
    activeNorreUser: ActiveNorreUser;
}

export const ACTIVE_NORRE_USER_STORAGE_KEY = "__active_norre_user";

export const deleteActiveNorreUserStorage = () => {
    localStorage.removeItem(ACTIVE_NORRE_USER_STORAGE_KEY);
};

export const ActiveNorreUserContext = React.createContext({
    activeNorreUser: null as ActiveNorreUser,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setActiveNorreUser: (value: ActiveNorreUser) => {},
});
