import { Dispatch, SetStateAction, useState } from "react";

type StoredStateType = string | number | boolean | object | null;

export const useStoredState = <T extends StoredStateType>(
    key: string,
    initialState: T,
    baseType?: "string" | "number" | "boolean" | "object"
): [state: T, setState: Dispatch<SetStateAction<T>>] => {
    const parse = (serialized: string | null): T => {
        if (serialized === null) return null as T;
        const typeToUse = baseType ?? typeof initialState;
        switch (typeToUse) {
            case "string":
                return serialized as T;
            case "number":
                return Number(serialized) as T;
            case "boolean":
                return (serialized === "true") as T;
            case "object":
                return JSON.parse(serialized) as T;
            default:
                return initialState;
        }
    };

    const currentState = parse(localStorage.getItem(key)) ?? initialState;

    const [state, setState] = useState<T>(currentState);

    const setStoredState: Dispatch<SetStateAction<T>> = (valueOrFunction) => {
        if (valueOrFunction instanceof Function) {
            setState((prev) => {
                const newValue = valueOrFunction(prev);
                updateStorageItem(key, newValue);
                return newValue;
            });
        } else {
            updateStorageItem(key, valueOrFunction);
            setState(valueOrFunction);
        }
    };

    return [state, setStoredState];
};

const updateStorageItem = <T extends StoredStateType>(
    key: string,
    value: T
) => {
    if (value === null) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, serialize(value));
    }
};

const serialize = <T extends StoredStateType>(value: Exclude<T, null>) => {
    switch (typeof value) {
        case "string":
            return value as string;
        case "number":
            return String(value);
        case "boolean":
            return value ? "true" : "false";
        case "object":
            return JSON.stringify(value);
    }
};
