import { parseDate } from "types/utils";

export const ACCESS_TOKEN_EXPIRES_AT = "__accessTokenExpiresAt";
export const REFRESH_TOKEN_EXPIRES_AT = "__RefreshTokenExpiresAt";

export const hasValidToken = (): boolean => {
    const accessTokenExpiration = getAccessTokenExpiresAt();
    const refreshTokenExpiration = getRefreshTokenExpiresAt();
    return (
        expiresInSeconds(accessTokenExpiration) > 0 ||
        expiresInSeconds(refreshTokenExpiration) > 0
    );
};

export const setAccessTokenExpiresAt = (expiresAt: string) => {
    localStorage.setItem(ACCESS_TOKEN_EXPIRES_AT, expiresAt);
};

export const getAccessTokenExpiresAt = (): Date | null => {
    const accessTokenExpiresAt = localStorage.getItem(ACCESS_TOKEN_EXPIRES_AT);
    return accessTokenExpiresAt ? parseDate(accessTokenExpiresAt) : null;
};

export const setRefreshTokenExpiresAt = (expiresAt: string) => {
    localStorage.setItem(REFRESH_TOKEN_EXPIRES_AT, expiresAt);
};

export const getRefreshTokenExpiresAt = (): Date | null => {
    const refreshTokenExpiresAt = localStorage.getItem(
        REFRESH_TOKEN_EXPIRES_AT
    );
    return refreshTokenExpiresAt ? parseDate(refreshTokenExpiresAt) : null;
};

export const expiresInSeconds = (expirationDate: Date | null) => {
    if (!expirationDate) return 0;
    const expiresInMilliseconds =
        expirationDate.getTime() - new Date().getTime();
    return Math.round(expiresInMilliseconds / 1000);
};
