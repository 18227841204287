import { SxProps, Theme } from "@mui/material/styles";
import { NORREBACKGROUND, NORREWHITE } from "Utils/styleConstants";

export const centerContent: SxProps<Theme> = {
    justifyContent: "center",
    alignItems: "center",
};

export const justifyCenter = {
    justifyContent: "center",
};

export const justifySpaceBetween = {
    justifyContent: "space-between",
};

export const alignCenter = {
    alignItems: "center",
};

export const alignSelfCenter = {
    alignSelf: "center",
};
export const flex1 = {
    flex: 1,
};

export const dFlex = {
    display: "flex",
};

export const wrap = {
    flexWrap: "wrap",
};
export const columnFlex = {
    display: "flex",
    flexDirection: "column",
};

export const overflowAuto = {
    overflow: "auto",
};

export const gap1 = {
    gap: 1,
};

export const gap2 = {
    gap: 2,
};

export const mlAuto = {
    marginLeft: "auto",
};
export const minW0 = {
    minWidth: 0,
};

export const minSize0 = {
    minWidth: 0,
    minHeight: 0,
};

export const backgroundWhite = {
    backgroundColor: NORREWHITE,
};

export const norreBackground = {
    backgroundColor: NORREBACKGROUND,
};

export const mb2 = {
    mb: 2,
};

export const mb1 = {
    mb: 1,
};

export const bold = {
    fontWeight: "bold",
};
