import { onError } from "@apollo/client/link/error";
import { handleLogoutWithoutHook } from "Entity/LogoutHandler";

export const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            if (
                err.message.includes("AnonymousUser") ||
                err.message.includes("Invalid token")
            ) {
                handleLogoutWithoutHook();
                return;
            }
        }
    }
    return forward(operation);
});
