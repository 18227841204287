/**
 * Get current host URL.
 *
 * @returns {string} Current host URL.
 *
 * @example
 * getHostURL(); // https://app.norre.fi
 * getHostURL(); // http://localhost:3000
 */
export const getHostURL = (): string => window.location.origin;
export const MEDICAL_TESTING_HOST_URL: string = "https://vpn-service.norre.fi";

/**
 * Check if current host is localhost (for development customized purpose)
 */
export const isDevEnv = (): boolean => {
    const hostURL = getHostURL();
    return (
        hostURL.startsWith("http://localhost") ||
        hostURL.startsWith("https://localhost") ||
        hostURL.startsWith("http://127.0.0.1") ||
        hostURL.includes("ngrok.io") ||
        hostURL.includes("localtunnel")
    );
};

/**
 * Check if current host is test server
 */
export const isTestEnv = (): boolean => {
    const hostURL = getHostURL();
    return hostURL.startsWith("https://app-test.norre.fi");
};

/**
 * Check if current host is production server
 */
export const isProdEnv = (): boolean => {
    return !(isDevEnv() || isTestEnv());
};

export const api_base: string = isDevEnv()
    ? "https://localhost:8000"
    : isTestEnv()
      ? "https://api-test.norre.fi"
      : "https://api.norre.fi";
export const medical_base: string = isDevEnv()
    ? "https://localhost:8020"
    : MEDICAL_TESTING_HOST_URL;

export const MEDICAL_CONTEXT = { clientName: "medical" };

export const STRONG_IDENTIFICATION_PATH = "oidc/authenticate/";

export const STRONG_IDENTIFICATION_URL =
    api_base + "/" + STRONG_IDENTIFICATION_PATH;
