import { SxProps, Theme } from "@mui/material/styles";

export const joinClassNames = (names: Array<string | undefined>): string => {
    return (
        names.filter((v) => {
            return v !== undefined;
        }) as string[]
    ).join(" ");
};

export const withSxProp = (
    sxProp: SxProps<Theme> | undefined,
    other: SxProps<Theme>
): SxProps<Theme> => {
    if (!sxProp) {
        return other;
    } else {
        return mergeSx(other, sxProp);
    }
};

export const mergeSx = (...sxProps: SxProps<Theme>[]): SxProps<Theme> => {
    return sxProps.reduce((prev, currentValue) => {
        return [
            ...(Array.isArray(prev) ? prev : [prev]),
            ...(Array.isArray(currentValue) ? currentValue : [currentValue]),
        ];
    }, [] as SxProps<Theme>);
};
