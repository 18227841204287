import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import fiTranslations from "localization/languages/fi/translation.json";
import fiPermTranslations from "localization/languages/fi/perms.json";
import enTranslations from "localization/languages/en/translation.json";
import { enUS as en, fi } from "date-fns/locale";
import {
    format as FNSformatDate,
    formatDistance,
    formatRelative,
    isDate,
} from "date-fns";

type LocalesType = {
    fi: Locale;
    en: Locale;
};
export const locales: LocalesType = { fi, en };

const FALLBACK_LANGUAGE = "fi";

const formatDate = (value: any, format: string, locale: Locale): string => {
    if (format === "timestamp") {
        return FNSformatDate(value, "Pp", { locale });
    }
    if (format === "short") {
        return FNSformatDate(value, "P", { locale });
    }
    if (format === "long") return FNSformatDate(value, "PPPP", { locale });
    if (format === "relative")
        return formatRelative(value, new Date(), { locale });
    if (format === "ago")
        return formatDistance(value, new Date(), {
            locale,
            addSuffix: true,
        });

    return FNSformatDate(value, format, { locale });
};
i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            translations: enTranslations,
        },
        fi: {
            translations: fiTranslations,
            perms: fiPermTranslations,
        },
    },
    fallbackLng: FALLBACK_LANGUAGE,
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations", "perms"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ",",
        format: (value, format, lng) => {
            if (format === undefined || lng === undefined) {
                return "undefined";
            } else if (isDate(value)) {
                return formatDate(
                    value,
                    format,
                    getFNSLocale(lng as keyof LocalesType),
                );
            } else {
                return value;
            }
        },
    },
    react: {
        useSuspense: true,
    },
});

export const getFNSLocale = (language: keyof LocalesType): Locale => {
    if (language in locales) {
        return locales[language];
    } else {
        return locales[FALLBACK_LANGUAGE];
    }
};

export default i18n;
