import React from "react";
import Box from "@mui/material/Box";
import { BoxProps } from "@mui/material/Box/Box";
import {withSxProp} from "Utils/styles";

interface Props {
    size?: string;
}

export const NorreLoading = ({ size, sx, ...other }: Props & BoxProps) => {
    const sizeUsed = size === undefined ? "2.5rem" : size;

    return (
        <Box
            {...other}
            className="loading"
            sx={withSxProp(sx, {
                minWidth: sizeUsed,
                minHeight: sizeUsed,
            })}
        ></Box>
    );
};
