import React from "react";
import { Button } from "@mui/material";
import { ButtonProps } from "@mui/material/Button/Button";
import { NorreLoading } from "components/common/loading/NorreLoading";
import { Link } from "react-router-dom";

export interface NorreButtonProps extends ButtonProps {
    loading?: boolean;
    to?: string;
}

export const NorreButton = ({
    loading = false,
    to,
    color,
    disabled,
    endIcon,
    variant,
    component,
    children,
    ref,
    ...other
}: NorreButtonProps) => {
    if (to) {
        component = Link;
    }
    return (
        <Button
            color={color || "primary"}
            disabled={disabled || loading}
            endIcon={loading ? <NorreLoading size="1.2rem" /> : endIcon}
            variant={variant || "outlined"}
            component={component || "button"}
            to={to}
            ref={ref}
            {...other}
        >
            {children}
        </Button>
    );
};
