export enum BreakPoint {
    "xl",
    "lg",
    "md",
    "sm",
    "xs",
}

export const SIDEBAR_WIDTH = "calc(5vw + 2rem)";

// COLOR DEFINITIONS
export const NORRERED = "#E94D4D";
export const NORREHEADER = "rgba(0,0,0,0.87)";

export const NORRETABLEHEADERSTYLE = {
    textTransform: "uppercase",
};

export const NORREACTION = "#00AFB1";
export const NORREPRIMARY = "#00AFB1";

export const NORREBLUE = "#1B2D51";
export const NORREDARKBACKGROUND = "#1B2D51";

export const NORRELIGHTGRAYTEXT = "#6c757d";
export const NORREGRAYTEXT = "#525252";
export const NORRESECONDARY = "#525252";
export const NORREDARKGRAY = "#525252";
export const NORREBLACK = "#000000";

export const NORREGREEN = "#02D202";
export const NORREDARKHOVER = "#007F80";

export const NORREBACKGROUND = "#F9F9F9";
export const NORRELIGHTBACKGROUND = "#F9F9F9";
export const NORRELIGHTGRAY = "#F9F9F9";

export const NORREWHITE = "#FFFFFF";

export const NORRETABBACKGROUND = "#F0F0F0";
export const NORREMEDIUMGRAY = "#F0F0F0";
export const NORRETABBORDER = "#D8D8D8";
export const TEXTDISABLED = "#A8A8A8";

export const NORRE_FONT: string = "'Open Sans', sans-serif";

export const NORREEDITGRAY = "#E0E0E0";
export const NORREPHONEBORDER = "#BBB";
export const NORREPHONEBUTTON = "#DDD";
export const NORREPHONEFRAME = "#EEE";

export const NORREERROR = "#F44336";
export const NORRETASKWARNING = "#fac402";
export const NORRETASKCOMPLETED = "#00bf0a";

export const NORREBOXSHADOW = "#D3D3D3";

export const NORREHIGHLIGHT = "orange";
export const NORREHIGHLIGHTDARK = "darkorange";

export const NORREDISABLED = "rgba(0, 0, 0, 0.26)";
