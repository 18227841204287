import { useNavigate } from "react-router-dom";
import {
    deleteActiveOrgCompany,
    deleteActiveOrgCustomer,
} from "Entity/ActiveOrgHandler";
import {
    ActiveAuthUserContext,
    ActiveNorreUserContext,
    deleteActiveNorreUserStorage,
    deleteActiveUserStorage,
} from "Entity/ActiveUser";
import {
    NorreFunctionalityContext,
    removeNorreFunctionalityStorage,
} from "Entity/Functionality";
import { useApolloClient } from "@apollo/client";
import { LOGIN_ABSOLUTE } from "urls";
import client from "Apollo/config";
import {
    ACCESS_TOKEN_EXPIRES_AT,
    REFRESH_TOKEN_EXPIRES_AT,
    setAccessTokenExpiresAt,
    setRefreshTokenExpiresAt,
} from "Entity/Authentication";
import {
    RevokeTokenDocument,
    useRevokeTokenMutation,
} from "Apollo/Mutations/AuthMutations/index.generated";
import { useContext } from "react";
import { ActiveCompanyContext, ActiveCustomerContext } from "AppContextWrapper";
import { setUser as setSentryUser } from "@sentry/react";

const onLogout = () => {
    setAccessTokenExpiresAt("");
    setRefreshTokenExpiresAt("");
};

const hasAnyLoginInformation = () => {
    return (
        !!localStorage.getItem(ACCESS_TOKEN_EXPIRES_AT) ||
        !!localStorage.getItem(REFRESH_TOKEN_EXPIRES_AT)
    );
};

export function useHandleLogout(): () => void {
    const navigate = useNavigate();
    const hookedClient = useApolloClient();
    const [revokeToken] = useRevokeTokenMutation({
        fetchPolicy: "network-only",
    });
    const { setActiveCompany } = useContext(ActiveCompanyContext);
    const { setActiveCustomer } = useContext(ActiveCustomerContext);
    const { setFunctionality } = useContext(NorreFunctionalityContext);
    const { setActiveAuthUser } = useContext(ActiveAuthUserContext);
    const { setActiveNorreUser } = useContext(ActiveNorreUserContext);

    function handleLogout() {
        navigate(LOGIN_ABSOLUTE);
        setActiveCompany(null);
        setActiveCustomer(null);
        setFunctionality(null);
        setActiveAuthUser(null);
        setActiveNorreUser(null);
        setSentryUser(null);
        onLogout();
        revokeToken();
        hookedClient.clearStore();
    }

    return handleLogout;
}

export const handleLogoutWithoutHook = () => {
    if (hasAnyLoginInformation()) {
        deleteActiveOrgCompany();
        deleteActiveOrgCustomer();
        deleteActiveUserStorage();
        deleteActiveNorreUserStorage();
        removeNorreFunctionalityStorage();
        setSentryUser(null);
        onLogout();
        client.mutate({
            mutation: RevokeTokenDocument,
            fetchPolicy: "network-only",
        });
        client.clearStore();
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }
};
