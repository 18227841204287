import React from "react";
import ReactDOM from "react-dom/client";
import "styles/index.css";
import App from "App";
import * as serviceWorker from "serviceWorker";
import { ThemeProvider } from "@mui/material/styles";
import "styles/fonts.css";
import "styles/modifiedBootstrap.scss";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import { NORRE_GLOBAL_THEME } from "Utils/theme";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

root.render(
    <React.StrictMode>
        <HelmetProvider>
            <ThemeProvider theme={NORRE_GLOBAL_THEME}>
                <CssBaseline />
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    autoHideDuration={4000}
                >
                    <App />
                </SnackbarProvider>
            </ThemeProvider>
        </HelmetProvider>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
