import { ID_EMPTY } from "Utils/constants";

export const ACTIVE_COMPANY_STORAGE_KEY = "__activeOrganizationCompany"
export const ACTIVE_CUSTOMER_STORAGE_KEY = "__activeOrganizationCustomer"

export const getActiveOrgCompany = (): string => {
    const companyId = localStorage.getItem(ACTIVE_COMPANY_STORAGE_KEY);
    return companyId ? companyId : ID_EMPTY;
};

export const deleteActiveOrgCompany = () => {
    localStorage.removeItem(ACTIVE_COMPANY_STORAGE_KEY);
};


export const getActiveOrgCustomer = (): string => {
    const customerId = localStorage.getItem(ACTIVE_CUSTOMER_STORAGE_KEY);
    return customerId ? customerId : ID_EMPTY;
};

export const deleteActiveOrgCustomer = () => {
    localStorage.removeItem(ACTIVE_CUSTOMER_STORAGE_KEY);
};
