import { InMemoryCache, makeVar } from "@apollo/client";

export const permissionDialogOpenVar = makeVar<boolean>(false);
export const nameProfileDialogOpenVar = makeVar<boolean>(false);
export const permissionDialogDataPropsViewIdVar = makeVar<null | string>(null);
export const permissionDialogDataPropsProfileIdVar = makeVar<null | string>(
    null
);
export const treeCurrentNodeVar = makeVar<null | string>(null);

const mergeTakeIncoming = { merge: (_ext: any, incoming: any) => incoming };

const cache = new InMemoryCache({
    typePolicies: {
        NodeType: {
            fields: {
                groups: mergeTakeIncoming,
                nextBranches: mergeTakeIncoming,
            },
        },
        TreeDataType: {
            fields: {
                branches: mergeTakeIncoming,
                nodes: mergeTakeIncoming,
            },
        },
        Query: {
            fields: {
                permissionDialogOpen: {
                    read() {
                        return permissionDialogOpenVar();
                    },
                },
                nameProfileDialogOpen: {
                    read() {
                        return nameProfileDialogOpenVar();
                    },
                },
                permissionDialogDataPropsViewId: {
                    read() {
                        return permissionDialogDataPropsViewIdVar();
                    },
                },
                permissionDialogDataPropsProfileId: {
                    read() {
                        return permissionDialogDataPropsProfileIdVar();
                    },
                },
                treeCurrentNode: {
                    read() {
                        return treeCurrentNodeVar();
                    },
                },
            },
        },
    },
});

export default cache;
