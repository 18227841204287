import { errorLink } from "Apollo/Links/ErrorLink";
import { retryLink } from "Apollo/Links/RetryLink";
import { authHttpLink, httpLink, medicalHttpLink } from "Apollo/Links/HttpLink";
import { ApolloClient, ApolloLink, from } from "@apollo/client";
import cache from "Apollo/cache";

const link = from([retryLink, errorLink, httpLink]);

const medicalLink = from([
    retryLink,
    //@ts-ignore
    medicalHttpLink,
]);

const authLink = from([
    retryLink,
    //@ts-ignore
    authHttpLink,
]);

const client = new ApolloClient({
    cache: cache,
    link: ApolloLink.split(
        (operation) => operation.getContext().clientName === "medical",
        medicalLink,
        ApolloLink.split(
            (operation) => operation.getContext().clientName === "auth",
            authLink,
            link,
        ),
    ),
});

export default client;
